<template>
    <div>
        <div class="content">


            <div class="B1">
                <img class="B1B1 showM" src="../assets/footer/DAVISIND_MV.png">
                <div class="B1B2">
                    <div class="B1B2_B1">
                        <ul>
                            <li><a href="/about">ABOUT</a></li>
                            <li><a href="/what-we-do">WHAT WE DO</a></li>
                        </ul>
                    </div>
                    <div class="B1B2_B2">
                        <ul>
                            <li><a href="/contact">CONTACT</a></li>
                            <li><a href="/blog">BLOG</a></li>
                        </ul>
                    </div>
                </div>
                <div class="B1B3">
                    <p class="B1B3_T1">DavisInd group is a Mexican company that provides engineering solutions to our
                        customers on the automotive, mining and food and beverage industry.</p>
                    <p class="B1B3_T2">Whether we do, fully integrated systems, manufacturing lines and project management.
                        We deliver unique solutions to the automation industry with both efficiency and precision</p>
                </div>
                <div class="B1B4">
                    <img class="B1B1 showD" src="../assets/footer/DAVISIND_MV.png">
                    <img class="B1B4_I1 showM" src="../assets/footer/ROBOTARM_MV.png">
                    <!--<img class="B1B4_I2 showM" src="../assets/footer/CIRCLEUP_MV.png">-->
                    <img class="B1B4_I1 showD" src="../assets/footer/ROBOTARM_DT.png">
                    <!--<img class="B1B4_I2 showD" src="../assets/footer/CIRCLEUP_DT.png">-->
                </div>
            </div>

            <div class="B2">
                <p>{{ year }} Davis Ind Group | All rights reserved</p>
                <div class="B2B2">
                    <!--<img src="../assets/footer/FB_DT.svg">
                    <img src="../assets/footer/IG_DT.svg">
                    <img src="../assets/footer/TW_DT.svg">-->
                    <a href="https://www.linkedin.com/company/davisind-group" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/footer/IN_DT.svg" alt="linked in social media">
                    </a>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    name: 'Footer',
    data() {
        return {
            year: null,
        }
    },

    mounted() {
        this.year = dayjs().format('YYYY');
    },
}
</script>

<style scoped>
.content {
    background-image: url('../assets/footer/BACKFOOTER_MV.png');
    width: 100%;
    height: 248.5981308411215VW;
    background-repeat: no-repeat;
    background-size: cover;
}

img.B1B1 {
    width: 40.887850467289724VW;
    height: 14.252336448598129VW;
    margin-top: 16.355140186915886VW;
    margin-left: 15.887850467289718VW;
    margin-bottom: 10.514018691588785VW;
}

.B1B2 {
    display: flex;
    margin-left: 14.485981308411214VW;
}

.B1B2_B1 {
    margin-right: 10.046728971962617VW;
}

.B1B2_B1 ul {
    margin: 0vw;
    padding-left: 5vw;
}

.B1B2_B1 li {
    color: var(--color-5);
    margin-bottom: 12vw;
}

.B1B2_B1 li a {
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    color: var(--color-5);
    font-family: var(--MOSB_Light);
    text-decoration-color: transparent;
}

.B1B2_B2 ul {
    margin: 0vw;
}

.B1B2_B2 li {
    margin-bottom: 12vw;
    color: var(--color-5);
}

.B1B2_B2 li a {
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    color: var(--color-5);
    font-family: var(--MOSB_Light);
    text-decoration-color: transparent;
}

.B1B3 {
    width: fit-content;
    margin-left: 14.485981308411214VW;
}

.B1B3 p {
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    font-family: var(--OpenSans);
    line-height: 6.107476635514019VW;
    color: var(--color-5);
    margin: auto;
}

p.B1B3_T1 {
    width: 78.5vw;
    margin-bottom: 7vw;
}

p.B1B3_T2 {
    width: 76vw;
}

.B1B4 {
    height: 81.8vw;
}

img.B1B4_I1 {
    width: 92.168224VW;
    height: auto;
}

img.B1B4_I2 {
    position: absolute;
    width: 27.570093457943923VW;
    height: 27.570093457943923VW;
    margin-left: -37vw;
    margin-top: 19vw;
}

.B2 {
    border-top: 0.1vw solid var(--color-5);
    padding-top: 9vw;
}

.B2 p {
    display: flex;
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    font-family: var(--OpenSans);
    color: var(--color-5);
    justify-content: center;
    margin: 0vw;
    margin-bottom: 7vw;
}

.B2B2 {
    display: flex;
    /*width: 42.99065420560748VW;*/
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.B2B2 img {
    width: 6.074766355140187VW;
    height: auto;
}

.showD {
    display: none !important;
}


@media(min-width: 768px) {

    .showD {
        display: block !important;
    }

    .showM {
        display: none !important;
    }

    .content {
        background-image: url('../assets/footer/BACKFOOTER_DT.png');
        height: 28.072916666666664VW;
    }

    .B1 {
        display: flex;
        height: 23vw;
    }

    img.B1B1 {
        position: absolute;
        width: 13.333333333333334VW;
        height: 4.635416666666667VW;
        margin-left: 32vw;
        margin-top: 5vw;
    }

    .B1B2 {
        display: block;
        margin-left: 7.864583333333333VW;
        margin-top: 5VW;
    }

    .B1B2_B1 {
        margin-right: 0vw;
    }

    .B1B2_B1 ul {
        padding-left: 2vw;
    }

    .B1B2_B1 li {
        margin-bottom: 3vw;
    }

    .B1B2_B1 li a {
        font-size: 0.7291666666666666VW;
    }

    .B1B2_B2 ul {
        padding-left: 2vw;
    }

    .B1B2_B2 li {
        margin-bottom: 3vw;
    }

    .B1B2_B2 li a {
        font-size: 0.7291666666666666VW;
    }

    .B1B3 {
        margin-top: 5VW;
        margin-left: 7.34375VW;
    }

    .B1B3 p {
        font-size: 0.9375VW;
        line-height: 1.7500000000000002VW;
    }

    p.B1B3_T1 {
        width: 23.5vw;
        margin-bottom: 2vw;
    }

    p.B1B3_T2 {
        width: 23.5vw;
    }

    img.B1B4_I1 {
        position: absolute;
        width: 31.041666666666668VW;
        height: auto;
        margin-top: 3.4vw;
        margin-left: 3vw;
    }

    img.B1B4_I2 {
        width: 6.145833333333333VW;
        height: 6.145833333333333VW;
        margin-left: 39vw;
        margin-top: 15vw;
    }

    .B2 {
        display: flex;
        width: 89.58333333333334VW;
        margin: auto;
        border-top: 0.1vw solid var(--color-5);
        padding-top: 0vw;
        flex-flow: row-reverse;
        height: 5vw;
        align-items: center;
    }

    .B2B2 img {
        width: 1.2166666666666666VW;
    }

    .B2 p {
        display: flex;
        font-size: 0.9375VW;
        font-weight: 400;
        font-family: var(--OpenSans);
        color: var(--color-5);
        justify-content: center;
        margin: 0vw;
        margin-bottom: 0vw;
        margin-right: 35vw;
    }

    .B2B2 {
        display: flex;
        width: 6.406249999999999VW;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        margin-left: 5vw;
    }




}</style>