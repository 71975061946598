<template >
  <div class="Navigator" :class="{'adjust':scrollP > 0}" >
    <div class="nav-cont" :class="{'pdd':scrollP > 0}">
      <!--<img :src="pcLogo" alt="logo" class="logo pc" :class="{'logodis':scrollP > 0}"/>
      <img :src="moLogoHor" alt="logo" class="logo pc" :class="{'hht':scrollP > 0,'logodis':scrollP < 0}"/>-->
      <a href="/">
        <div class="logo divlogo" :class="{'hht':scrollP > 0,'logodis':scrollP > 0 || ruta == '/what-we-do' || ruta == '/blog' || rutaname == 'blog-post' || ruta == '/contact' || ruta == '/quality-policy' || ruta == '/project/p758-flex-n-gate'}"></div>      
      </a>

      <!---<img :src="moLogo" alt="logo" class="logo mo" :class="{'hht':scrollP > 0}"/>-->

      <img
        src="../assets/bars.svg"
        alt=""
        class="bars mo"
        :class="{'blue': scrollP > 0 || ruta == '/what-we-do' || ruta == '/blog' || rutaname == 'blog-post' || ruta == '/contact' || ruta == '/quality-policy' || ruta == '/project/p758-flex-n-gate'}"
        @click="open = true"
      />
      <div v-if="linkColor === 'Blanco'" class="menu-cont" :class="{'activated' : open}"  >
        <img
          src="../assets/cross.svg"
          alt=""
          class="bars mo"
          @click="open = false"
        />
        <a href="/" class="menu-b first active" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">HOME <div class="bar" :class="{'active':ruta == '/'}"></div></a>
        <a href="/about" @click="open = false" class="menu-b" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">ABOUT US<div class="bar" :class="{'active':ruta == '/about'}"></div></a>
        <a href="/what-we-do" @click="open = false" class="menu-b" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">WHAT WE DO<div class="bar" :class="{'active':ruta == '/what-we-do'}"></div></a>
        <a href="/blog" @click="open = false" class="menu-b" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">BLOG<div class="bar" :class="{'active':ruta == '/blog'}"></div></a>
        <a href="/contact" @click="open = false" class="menu-b" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">CONTACT<div class="bar" :class="{'active':ruta == '/contact'}"></div></a>
        <!-- <div class="search-cont" v-if="buscador">
          <button><img src="../assets/lupa.svg" alt="" class="lupa" /></button>
          <input type="search" id="site-search" name="q" />
        </div> -->
       <!--  <div class="sm-cont">
          <a href="https://www.facebook.com/BatareteHermosillo" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/fb-i.svg" alt="sm" class="sm-i" />
          </a>
          <a href="https://www.instagram.com/demarepasteles" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/ig-i.svg" alt="sm" class="sm-i" />
          </a>
         <a href="" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/tw-i.svg" alt="sm" class="sm-i" />
          </a>
        </div> -->
      </div>
      <div v-else  class="menu-cont" :class="{'activated' : open}"  >
        <img
          src="../assets/cross.svg"
          alt=""
          class="bars mo"
          @click="open = false"
        />
        <a href="/" class="menu-a first active" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">HOME <div class="bar" :class="{'active':ruta == '/'}"></div></a>
        <a href="/about" @click="open = false" class="menu-a" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">ABOUT US<div class="bar" :class="{'active':ruta == '/about'}"></div></a>
        <a href="/what-we-do" @click="open = false" class="menu-a" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">WHAT WE DO<div class="bar" :class="{'active':ruta == '/what-we-do'}"></div></a>
        <a href="/blog" @click="open = false" class="menu-a" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">BLOG<div class="bar" :class="{'active':ruta == '/blog'}"></div></a>
        <a href="/contact" @click="open = false" class="menu-a" :class="{'fcwhite':ruta == '/about' &&  scrollP < 1}">CONTACT<div class="bar" :class="{'active':ruta == '/contact'}"></div></a>
        <!-- <div class="search-cont" v-if="buscador">
          <button><img src="../assets/lupa.svg" alt="" class="lupa" /></button>
          <input type="search" id="site-search" name="q" />
        </div> -->
       <!--  <div class="sm-cont">
          <a href="https://www.facebook.com/BatareteHermosillo" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/fb-i.svg" alt="sm" class="sm-i" />
          </a>
          <a href="https://www.instagram.com/demarepasteles" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/ig-i.svg" alt="sm" class="sm-i" />
          </a>
         <a href="" target="_blank" rel="noopener noreferrer">
            <img src="../assets/custom/tw-i.svg" alt="sm" class="sm-i" />
          </a>
        </div> -->
      </div>
    </div>
  </div>
  
</template>
<script>
import pcLogo from "../assets/logonav.png";
import moLogo from "../assets/logonav-mo.png";
import moLogoHor from "../assets/logonav.png";
export default {
  props: {
    linkColor: {
      type:  String,
      default: "ola"
    }
  },
  data() {
    return {
      buscador: true /* Activar buscador */,
      moLogo,
      pcLogo,
      moLogoHor,
      open: false,
      scrollP: 0,
      ruta:this.$route.path,
      rutaname:this.$route.name,
    };
  },
  methods: {
    scroll(event){
      let number = event.target.documentElement.scrollTop;
      let offset = number * 1;
      localStorage.setItem('scroll',offset)
      this.scrollP = localStorage.getItem('scroll')
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
};
</script>
<style scoped>
.pc {
  display: block;
}
.mo {
  display: none;
}
.fcwhite{
  color:white!important;
}
.Navigator {
  transition: .5s;
  width: 100%;
  position: fixed;
    z-index: 1000;
}
.nav-cont {
  transition: .5s;
  width: 88.698vw;
  margin: 0 auto;
  padding: 3.594vw 0 2.344vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Navigator .logo {
  width: auto;
  height: 8.021vw;
  transition: .15s;
}
.menu-cont {
  display: flex;
  align-items: center;
}
/**items menu */
.menu-a {
  font-family: var(--font-titulo1);
  font-size: var(--fs-menupc);
  color: inherit;
  color: var(--co-menui);
  margin: 0 2.604vw;
  transition: 0.5s;
  text-decoration-color: transparent;
}
.menu-b {
  font-family: var(--font-titulo1);
  font-size: var(--fs-menupc);
  color: inherit;
  color: #ffffff;

  margin: 0 2.604vw;
  transition: 0.5s;
  text-decoration-color: transparent;
}
.menu-b .bar {
    background-color: transparent;
    height: 0.2vw;
    width: 100%;
    margin-top: 0.4vw;
    border-radius: 4vw;
    transition: .5s;
}
.menu-b:hover .bar {
    background-color: #262FFD;
    
}
.menu-b .bar.active{
    background-color: #262FFD;
}
.menu-a .bar {
    background-color: transparent;
    height: 0.2vw;
    width: 100%;
    margin-top: 0.4vw;
    border-radius: 4vw;
    transition: .5s;
}
.menu-a:hover .bar {
    background-color: #262FFD;
    
}
.menu-a .bar.active{
    background-color: #262FFD;
}
/**buscador */
.search-cont {
  width: 8.542vw;
  height: 1.771vw;
  display: flex;
  align-items: center;
  border: var(--im-border);
  border-radius: 1.563vw;
}
.search-cont button {
  background: none;
  border: none;
  padding: 0;
  width: 1.785vw;
}
.search-cont button img {
  width: 0.885vw;
  background: none;
  border: none;
  margin-left: 0.732vw;
  display: flex;
  filter: var(--lupapc-color);
}
.search-cont input#site-search {
  width: 100%;
  height: auto;
  background: none;
  border: none;
  font-family: var(--font-titulo2);
  font-size: var(--fs-menupc);
  color: var(--co-menui);
}

/**social media cont */
.sm-cont {
  width: 2.917vw;
  height: 1.406vw;
  margin-left: 0.886vw;
  display: flex;
  justify-content: space-between;
}
.sm-cont a {
  display: flex;
}
.sm-cont img {
  width: 1.354vw;
  filter: var(--sm-icon-color);
}
.sm-cont img:hover {
  filter: var(--sm-icon-hover);
}
  .adjust{
    background-color: white;
  }

  .Navigator .logo {
    background-image: url('../assets/logonav.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
.logodis {
    background-image: url('../assets/logo-bnr.png')!important;
}
.adjust .menu-b{
  color: #000 !important;
}
@media (min-width: 768px) {
  .hht {
    height: 4.221vw!important;
}
.pdd {
    padding: .4vw 0 .4vw!important;
}

  .Navigator .logo {
    width: 11.563vw;
    height: 4.781vw;
  }
}
/**MOBILE STYLES */
@media (max-width: 768px) {
  .pc {
    display: none;
  }
  .mo {
    display: block;
  }
  .bars {
    cursor: pointer;
    width: 10.628vw;
    filter: invert(98%) sepia(0%) saturate(57%) hue-rotate(191deg) brightness(120%) contrast(100%);
  }
  .blue{
    filter: invert(17%) sepia(97%) saturate(4851%) hue-rotate(241deg) brightness(93%) contrast(114%);
  }
  .activated{
    display: flex!important;
  }
  .activated .bars,.activated .sm-cont img{
    filter: var(--co-menui-mo);
  }
  .activated .bars{
    width: 7.488vw;
  }
  .activated .sm-cont img{
    width: 9.179vw;
  }
  .menu-cont {
    display: none;
    position: fixed;
    background: white;
    top: 0;
    right: 0;
    width: 54.831vw;
    height: 100%;
    z-index: 100;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: scroll;
    padding: 10.386vw 7.971vw 10.386vw 14.493vw;
  }
  .nav-cont {
    /*width: 86.715vw;*/
    width: 77.715vw;
    margin: 0 auto;
    padding: 4.106vw 0 4.831vw;
}
  .menu-a {
    font-family: var(--font-titulo1);
    font-size: var(--fs-menumo);
    color: var(--color-menumo);
    font-weight: 500;
    margin: 4.71vw 0;
    transition: 0.5s;
    text-align: right;
  }
  .menu-b {
    font-family: var(--font-titulo1);
    font-size: var(--fs-menumo);
    color: var(--color-menumo);
    font-weight: 500;
    margin: 4.71vw 0;
    transition: 0.5s;
    text-align: right;
  }
  .fcwhite{
    color: black!important;
  }
  .menu-a.first {
    margin: 18.841vw 0 4.71vw!important;
  }
  .menu-b.first {
    margin: 18.841vw 0 4.71vw!important;
  }
 /* .divlogo{
    display: none;
  }*/
  .Navigator .logo {
    width: 42.29vw;
    height: 23.43vw;
  }
  .hht {
    height: 16.184vw!important;
  }
  .search-cont {
    width: 54.831vw;
    height: 16.908vw;
    border-radius: 7.246vw;
    border: var(--im-border-mo);
  }
  .search-cont button  {
    width: 15.744vw;  
}
  .search-cont button img {
    width: 8.744vw; 
    margin-left: 6.498vw; 
  filter: var(--lupamo-color);
}
  .search-cont input#site-search {
    font-family: var(--font-parrafo);
    font-size: var(--fs-menumo);
    color: var(--color-menumo);
}
.sm-cont {
    width: 20.831vw;
    height: 13.527vw;
    margin-top: 7.488vw;
    margin-left: 0.886vw;
    display: flex;
    justify-content: space-between;
}
.sm-cont img {
    width: 14.251vw; 
}
}
</style>